
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppCommonExercise} from "@/models/app/common/exercise";

// Components
import {
  ElDialog,
  ElButton,
} from "element-plus";
import {Search} from "@element-plus/icons-vue";

@Options({
  props: [
    "exercise",
  ],
  components: {
    ElDialog,
    ElButton,
    Search,
  },
})
export default class AppCoachResourcesRootExercisesFormLookupVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  videoUrl: string | null = "";

  exercise: AppCommonExercise = new AppCommonExercise();

  getVideoURL(): void {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = this.exercise.content.url.match(regExp);
    const id = (match&&match[7].length==11) ? match[7] : false;

    this.videoUrl = `https://www.youtube.com/embed/${id}`;
  }

  updated(): void {
    this.getVideoURL();
  }
}
