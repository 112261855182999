
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppCommonDiet} from "@/models/app/common/diet";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import {InfoFilled} from "@element-plus/icons-vue";
import AppCoachResourcesRootDietsFormLookupVue from "@/views/app/coach/resources/root/diets/form/lookup.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    InfoFilled,
    AppCoachResourcesRootDietsFormLookupVue,
  },
})
export default class AppCoachResourcesRootDietsListIndexVue extends Vue {
  isLoading: boolean | null = false;

  diets: Array<AppCommonDiet> = [];

  async getDiets(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/administration/diets`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.diets = r.data.sort(function (a, b) {
          if (a.content.name < b.content.name) {
            return -1;
          }
          if (a.content.name > b.content.name) {
            return 1;
          }
          return 0;
        });
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getDiets();
  }
}
