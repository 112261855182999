import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06095a63"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-coach-resources-root-diets-form-lookup" }
const _hoisted_2 = { class: "action" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, {
        type: "primary",
        class: "el-button--grey el-button--square",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDialogVisible = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Search)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_dialog, {
      title: _ctx.dietTitle,
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "content",
          innerHTML: _ctx.diet.content.description
        }, null, 8, _hoisted_3)
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}