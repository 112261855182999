
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachResourcesNavigationVue from "@/components/coach/resources/navigation.vue";
import AppCoachResourcesRootExercisesIndexVue from "@/views/app/coach/resources/root/exercises/index.vue";
import AppCoachResourcesRootDietsIndexVue from "@/views/app/coach/resources/root/diets/index.vue";
import AppCoachResourcesRootSupplementsIndexVue from "@/views/app/coach/resources/root/supplements/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachResourcesNavigationVue,
    AppCoachResourcesRootExercisesIndexVue,
    AppCoachResourcesRootDietsIndexVue,
    AppCoachResourcesRootSupplementsIndexVue,
  },
})
export default class AppCoachResourcesRootIndexVue extends Vue {}
